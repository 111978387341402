.project {
  width: 100%;
  /* height: calc(100vh - 100px); */
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
 
}

.project h1 {
  margin-top: auto;
  color: #3e497a;
  font-size: 35px;
 
}
.project img {
  width: 450px;
  border-radius: 10px;
}

.project p {
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
  font-size: 20px;
  color: #3e497a;
}

.project svg {
  font-size: 60px;
  color: #3e497a;
}

.project a {
  margin-top: 50px;
}